<!--三级页面 -->
<template>
  <div class="threePage publicPage">
    <div class="content">
      <div class="top">
        <ititle :text="address[2]"></ititle>
        <div class="address">{{ address.join(">") }}</div>
      </div>
      <div class="titleBox">
        <span class="title">{{ pageInfo.title }}</span>
        <div>
          <span>作者：{{ pageInfo.writer }}</span>
          <span class="time">时间：{{ timefmt(pageInfo.write_time) }}</span>
        </div>
      </div>
      <div class="page" v-html="pageInfo.content"></div>
      <div class="next" v-if="next" @click="nextClick">
        <span>下一条：</span>
        <span>{{ next.title }}</span>
      </div>
      <div class="btnBox">
        <el-button size="small" type="primary" @click="goHome">返回首页</el-button>
        <el-button size="small" type="primary" @click="$router.back()">返回列表页</el-button>
      </div>
    </div>
    <sfoot></sfoot>
  </div>
</template>

<script>
import ititle from "../components/ititle.vue";
import { get_article } from "../request/apis";
import dayjs from "dayjs";
import sfoot from "./components/sfoot.vue";
export default {
  name: "",
  components: { ititle, sfoot },
  props: [],
  data() {
    return {
      address: [],
      pageInfo: {},
      next: {},
      query: {},
    };
  },
  computed: {},
  watch: {
    //监听路由参数变化
    $route: {
      handler(val) {
        this.query = val.query;
        this.init(val.query);
      },
      immediate: true,
      deep: true,
    },
  },
  created() {},
  mounted() {},
  methods: {
    //初始化
    init(query) {
      this.address = query.address;
      this.get_article(query.pageId);
    },
    //根据id获取文章
    get_article(id, isNext) {
      get_article(id).then((res) => {
        if (!isNext) {
          this.pageInfo = res;
          if (res.next_id) {
            this.get_article(res.next_id, true);
          } else {
            this.next = null;
          }
        } else {
          this.next = res;
        }
      });
    },
    //时间格式化
    timefmt(date) {
      return dayjs(date).format("YYYY-MM-DD");
    },
    //点击下一条
    nextClick() {
      this.get_article(this.next.id);
    },
    //返回首页
    goHome() {
      this.$router.push({
        path: "/homePage",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.threePage {
  width: 100%;
  height: calc(100vh - 70px);
  position: relative;
  align-items: center;
  justify-content: center;
  padding-bottom: 100px;
  box-sizing: border-box;
  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-image: url("../assets/图书馆背景图片.jpg");
    filter: blur(6px);
  }
  .content {
    width: 1100px;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 200px);
    background-color: #fff;
    box-sizing: border-box;
    padding: 0px 20px;
    border-radius: 4px;
    .top {
      border-bottom: 1px solid #eee;
      display: flex;
      align-items: center;
      .address {
        margin-left: auto;
        flex-shrink: 0;
      }
      /deep/ .ititle {
        padding: 0px;
        height: 40px;
        .text {
          font-size: 20px;
          font-weight: normal;
        }
        .line {
          height: 20px;
        }
      }
    }
    .titleBox {
      display: flex;
      flex-direction: column;
      text-align: center;
      .title {
        font-size: 24px;
        font-weight: bold;
        padding: 20px 0px;
        text-align: center;
      }
      span {
        margin-right: 40px;
      }
    }
    .page {
      height: calc(100% - 180px);
      overflow-y: auto;
      box-sizing: border-box;
      padding: 20px;
      border-bottom: 1px solid #eee;
      /deep/ * {
        line-height: 2;
      }
    }
    .next {
      cursor: pointer;
      padding: 10px 0px;
      &:hover {
        color: #f00;
      }
    }
    .btnBox {
      margin: 0 auto;
      padding: 10px 0px;
    }
  }
}
@media screen {
  @media (max-width: 600px) {
    .threePage {
      height: auto;
      padding: 0px;
      background-color: #fff;
      flex-direction: column;
      .content {
        flex-direction: column;
        width: 100%;
        padding: 0px;
        .top {
          flex-direction: column;
          align-items: flex-start;
          .address {
            margin-left: 0px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
</style>
